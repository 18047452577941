@import "/home/ec2-user/workspace/mpenfonds_vrf-app_release_1.56.x/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/mpenfonds_vrf-app_release_1.56.x/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.skr-fieldset {
  &.vrf-compact-fieldset {
    padding-left: 1rem;

    .editor-component {
      padding-left: 1rem;
      margin-bottom: 1rem;
   }
  }
}