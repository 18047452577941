@import "/home/ec2-user/workspace/mpenfonds_vrf-app_release_1.56.x/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/mpenfonds_vrf-app_release_1.56.x/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.read-only {
  // this is based on vl-input-field
  font-family: $vl-base-font;
  font-size: $vl-form-font-size;
  color: $vl-text-color;
  line-height: $vl-form-input-height;
  padding: 0;
}

.vl-input-field:-ms-input-placeholder {
  color: $vl-placeholder-color;
}